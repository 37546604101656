<template>
	<v-form
		ref="emailTemplateForm"
		v-model.trim="formValid"
		lazy-validation
		v-on:submit.stop.prevent="updateEmailTemplateSetting"
	>
		<v-sheet>
			<v-layout class="py-3 px-3">
				<v-flex class="py-0">
					<div class="sub-heading primary--text">Email Template</div>
				</v-flex>
				<v-spacer></v-spacer>
				<v-flex class="py-0 d-flex justify-content-end">
					<v-btn tile depressed class="ml-2" @click="$router.go(-1)">Cancel</v-btn>
					<v-btn
						tile
						depressed
						color="blue darken-4 white--text"
						class="ml-2"
						v-on:click="updateEmailTemplateSetting"
						>Save</v-btn
					>
				</v-flex>
			</v-layout>
			<v-row>
				<v-col md="8" lg="9" class="email-template-scroll">
					<div class="mb-5 d-flex">
						<div class="w-200 my-auto px-2">
							<label for="" class="fw-500">Template Name</label>
						</div>
						<v-flex>
							<v-text-field
								hide-details
								outlined
								readonly
								placeholder="Template Name"
								v-model="emailTemplate.template"
							></v-text-field>
						</v-flex>
					</div>
					<div class="mb-5 d-flex">
						<div class="w-200 my-auto px-2">
							<label for="" class="fw-500">Subject</label>
						</div>
						<v-flex>
							<v-text-field
								hide-details
								outlined
								placeholder="Subject"
								v-model="emailTemplate.subject"
							></v-text-field>
						</v-flex>
					</div>
					<div class="mb-5 d-flex">
						<div class="w-200 pa-2">
							<label for="" class="fw-500">Admin</label>
						</div>
						<v-flex>
							<ckeditor
								:editor="editor"
								v-model="emailTemplate.admin_body"
								:config="editorConfig"
							></ckeditor>
						</v-flex>
					</div>
					<div class="mb-5 d-flex">
						<div class="w-200 pa-2">
							<label for="" class="fw-500">Staff</label>
						</div>
						<v-flex>
							<ckeditor
								:editor="editor"
								v-model="emailTemplate.customer_body"
								:config="editorConfig"
							></ckeditor>
						</v-flex>
					</div>
					<div class="mb-5 d-flex" v-if="false">
						<div class="w-200 pa-2">
							<label for="" class="fw-500">Staff</label>
						</div>
						<v-flex>
							<ckeditor
								:editor="editor"
								v-model="emailTemplate.engineer_body"
								:config="editorConfig"
							></ckeditor>
						</v-flex>
					</div>
					<div class="mb-5 d-flex" v-if="false">
						<div class="w-200 my-auto px-2">
							<label for="" class="fw-500">In-App Notification</label>
						</div>
						<v-flex>
							<v-text-field
								hide-details
								outlined
								placeholder="In-App Notification"
								v-model="in_app_notification"
							></v-text-field>
						</v-flex>
					</div>
				</v-col>
				<v-col md="4" lg="3" class="email-template-scroll">
					<div class="sub-heading primary--text mb-3">Variables</div>
					<div class="fw-500 mb-3 pointer" v-for="(row, index) in availableVariables" :key="index">
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span
									v-on="on"
									class="cursor-pointer"
									ref="text"
									v-on:click="copyText(index, row)"
									v-bind="attrs"
									v-text="`${row}`"
								></span>
							</template>
							<span>Click to Copy</span>
						</v-tooltip>
					</div>
				</v-col>
			</v-row>
		</v-sheet>
	</v-form>
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toSafeInteger } from "lodash";
import { GetEmailTemplate, UpdateEmailTemplates } from "@/core/lib/setting.lib";
export default {
	name: "email-template-update",
	data() {
		return {
			formValid: true,
			template_name: null,
			subject: null,
			templateId: 0,
			emailTemplate: {
				customer_body: null,
				engineer_body: null,
				admin_body: null,
				mobile_app: null,
				template: null,
				subject: null,
			},
			in_app_notification: null,
			editor: ClassicEditor,
			availableVariables: new Array(),
			editorConfig: {
				// The configuration of the editor.
			},
		};
	},
	methods: {
		selectText(element) {
			let range;
			if (document.selection) {
				// IE
				range = document.body.createTextRange();
				range.moveToElementText(element);
				range.select();
			} else if (window.getSelection) {
				range = document.createRange();
				range.selectNode(element);
				window.getSelection().removeAllRanges();
				window.getSelection().addRange(range);
			}
		},
		copyText(index, variable) {
			try {
				console.log(variable);
				this.selectText(this.$refs.text[index]);
				document.execCommand("copy");
				//ClearEventBus.$emit("clear:success");
				/* SuccessEventBus.$emit(
          "update:success",
          variable + " copied successfully."
        ); */
				window.getSelection().removeAllRanges();
			} catch (error) {
				this.logError(error);
			}
		},
		getOneEmailTemplateSetting() {
			const _this = this;
			GetEmailTemplate(_this.templateId)
				.then((data) => {
					_this.availableVariables = data.variables || new Array();
					_this.group = data.group || null;
					_this.emailTemplate = new Object({
						customer_body: data.customer_body,
						engineer_body: data.engineer_body,
						admin_body: data.admin_body,
						mobile_app: data.mobile_app,
						template: data.template,
						subject: data.subject,
					});
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		updateEmailTemplateSetting() {
			const _this = this;
			if (!_this.$refs.emailTemplateForm.validate()) {
				return false;
			}
			_this.formLoading = true;
			UpdateEmailTemplates(_this.templateId, _this.emailTemplate)
				.then(() => {
					_this.pageCreated = true;
					_this.$router.go(-1);
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.formLoading = false;
					_this.$router.push({ name: "setting", query: { tab: "email_template" } });
				});
		},
	},
	mounted() {
		const _this = this;
		_this.templateId = toSafeInteger(_this.$route.params.id);
		if (_this.templateId > 0) {
			_this.getOneEmailTemplateSetting();
		}
	},
};
</script>
